import { OAuthTokens } from '../components/AuthorizationServer'
import { AuthorizationServerRegistry } from '../components/AuthorizationServerRegistry'
import { ERROR_TITLES, errorHandler } from '../errors'

// https://www.rfc-editor.org/rfc/rfc6749.html#section-4.1.2
export const doesUrlContainOAuthResponse = () => {
  const currentSearchParams = new URLSearchParams(window.location.search)

  if (!currentSearchParams.get('state')) {
    return false
  }

  return !!currentSearchParams.get('code') || !!currentSearchParams.get('error')
}

const isOnRedirectPage = (redirectUri: URL) => {
  if (!doesUrlContainOAuthResponse()) {
    return false
  }

  if (redirectUri.origin !== window.location.origin) {
    return false
  }

  if (redirectUri.pathname !== window.location.pathname) {
    return false
  }

  const redirectUriSearchParams = new URLSearchParams(redirectUri.search)
  const currentSearchParams = new URLSearchParams(window.location.search)

  for (const key of redirectUriSearchParams.keys()) {
    if (currentSearchParams.get(key) !== redirectUriSearchParams.get(key)) {
      return false
    }
  }

  return true
}

export const checkRedirectFlowTokens = async ({
  redirectUri,
  clientId,
}: {
  redirectUri?: string
  clientId: string
}): Promise<OAuthTokens | undefined> => {
  try {
    if (!redirectUri || !isOnRedirectPage(new URL(redirectUri))) {
      return
    }

    const authServer = await AuthorizationServerRegistry.getInstance()

    const tokens = await authServer.verifyLogin({
      urlWithLoginParams: new URL(window.location.href),
      isOnPageFlow: false,
      redirectUri,
      clientId,
    })

    return tokens
  } catch (error) {
    errorHandler(error, { errorTitle: ERROR_TITLES.VerifyLoginFailedForRedirectFlow })
    // merchantLogger.error("Couldn't handle redirect", error)
  }
}
