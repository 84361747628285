import { InteractionModes } from '@klarna/flow-interaction-mode/dist/constants'
import { SdkConfigSchema } from '@klarna-web-sdk/utils/src/schema'
import { array, boolean, literal, nativeEnum, number, object, string, union } from 'zod'

import {
  EffectiveUxModes,
  PaymentRequestState,
  ShippingType,
  ShippingTypeAttributes,
} from './constants'

export const PaymentRequestStateZ = nativeEnum(PaymentRequestState)
export const ShippingTypeZ = nativeEnum(ShippingType)
export const ShippingTypeAttributesZ = nativeEnum(ShippingTypeAttributes)
export const EffectiveUxModesZ = nativeEnum(EffectiveUxModes)

export const Address = object({
  city: string(),
  country: string().regex(/^[A-Z]{2}$/),
  postalCode: string().optional(),
  region: string().optional(),
  streetAddress: string(),
  streetAddress2: string().optional(),
})

export const ShippingOption = object({
  shippingType: ShippingTypeZ,
  shippingTypeAttributes: array(ShippingTypeAttributesZ).optional(),
  shippingCarrier: string().max(255).optional(),
})

export const ShippingOptionAPI = object({
  shipping_type: ShippingTypeZ,
  shipping_type_attributes: array(ShippingTypeAttributesZ).optional(),
  shipping_carrier: string().max(255).optional(),
})

export const Shipping = array(
  object({
    address: Address.optional(),
    recipient: object({
      attention: string().optional(),
      email: string().optional(),
      familyName: string(),
      givenName: string(),
      phone: string().optional(),
    }).optional(),
    shippingOption: ShippingOption.optional(),
    shippingReference: string().optional(),
  })
).optional()

export const Buyer = object({
  address: Address.optional(),
  email: string().optional(),
  familyName: string().optional(),
  givenName: string().optional(),
  phone: string().optional(),
})

export const LineItem = object({
  imageUrl: string().optional(),
  name: string(),
  productIdentifier: string().optional(),
  productUrl: string().optional(),
  quantity: number(),
  reference: string().optional(),
  shippingReference: string().optional(),
  totalAmount: number(),
  totalTaxAmount: number(),
  unitPrice: number(),
})

export const PaymentRequestData = object({
  buyer: Buyer.optional(),
  currency: string(),
  merchantReference: string().optional(),
  paymentAmount: number(),
  lineItems: array(LineItem).optional(),
  paymentReference: string().optional(),
  shipping: Shipping,
  config: object({
    redirectUrl: string().optional(),
  }).optional(),
})
export const PaymentRequestDataAllOptional = PaymentRequestData.extend({
  currency: string().optional(),
  paymentAmount: number().optional(),
})

export const PaymentRequestOptions = object({
  interactionMode: union([
    literal(InteractionModes.ON_PAGE),
    literal(InteractionModes.DEVICE_BEST),
    literal(InteractionModes.REDIRECT),
  ]).optional(),
})

export const KlarnaUser = object({
  klarnaUserId: string(),
  givenName: string(),
  familyName: string(),
  email: string(),
  phone: string(),
  phoneVerified: boolean(),
  emailVerified: boolean(),
  address: Address,
  locale: string(),
  dateOfBirth: string(),
  nationalIdentification: object({
    number: string(),
    country: string(),
  }),
  shipping: Shipping,
}).nullable()

export const StateContextSchema = object({
  distribution: object({
    url: string(),
  })
    .partial()
    .nullable(),
  paymentConfirmationToken: string().nullable(),
  paymentAuthorizationId: string().nullable(),
  userAccountProfile: KlarnaUser,
  userAccountLinking: object({
    userAccountLinkingRefreshToken: string(),
    userAccountLinkingIdToken: string(),
  }).nullable(),
})
  .partial()
  .nullable()

export const PaymentRequestResponse = object({
  expiresAt: string(),
  paymentRequest: PaymentRequestData.extend({
    config: object({
      redirectUrl: string().optional(),
    }).optional(),
  })
    .partial()
    .nullable(),
  paymentRequestId: string(),
  previousState: PaymentRequestStateZ.optional(),
  state: PaymentRequestStateZ,
  stateContext: StateContextSchema.optional(),
})

/**
 * Backend API related schemas
 */

const AddressAPI = object({
  city: string(),
  country: string().regex(/^[A-Z]{2}$/),
  postal_code: string().optional(),
  region: string().optional(),
  street_address: string(),
  street_address2: string().optional(),
})

const ShippingAPI = array(
  object({
    address: AddressAPI.optional(),
    recipient: object({
      attention: string().optional(),
      email: string().optional(),
      family_name: string(),
      given_name: string(),
      phone: string().optional(),
    }).optional(),
    shipping_option: ShippingOptionAPI.optional(),
    shipping_reference: string().optional(),
  })
).optional()

const KlarnaUserAPI = object({
  klarna_user_id: string(),
  given_name: string(),
  family_name: string(),
  email: string(),
  phone: string(),
  phone_verified: boolean(),
  email_verified: boolean(),
  address: AddressAPI,
  locale: string(),
  date_of_birth: string(),
  national_identification: object({
    number: string(),
    country: string(),
  }),
  shipping: ShippingAPI,
}).nullable()

export const StateContextSchemaAPI = object({
  distribution: object({
    url: string(),
  })
    .partial()
    .nullable(),
  payment_confirmation_token: string().nullable(),
  payment_authorization_id: string().nullable(),
  user_account_profile: KlarnaUserAPI,
  user_account_linking: object({
    user_account_linking_refresh_token: string(),
    user_account_linking_id_token: string(),
  }).nullable(),
})
  .partial()
  .nullable()

const PaymentRequestDataAPI = object({
  buyer: object({
    address: AddressAPI.optional(),
    email: string().optional(),
    family_name: string().optional(),
    given_name: string().optional(),
    phone: string().optional(),
  }).optional(),
  currency: string(),
  merchant_reference: string().optional(),
  payment_amount: number(),
  line_items: array(
    object({
      image_url: string().optional(),
      name: string(),
      product_identifier: string().optional(),
      product_url: string().optional(),
      quantity: number(),
      reference: string().optional(),
      shipping_reference: string().optional(),
      total_amount: number(),
      total_tax_amount: number(),
      unit_price: number(),
    })
  ).optional(),
  payment_reference: string().optional(),
  shipping: ShippingAPI,
  config: object({
    redirect_url: string().optional(),
  }).optional(),
})

export const PaymentRequestBodyAPI = object({
  ...PaymentRequestDataAPI.shape,
  internal: object({
    effective_ux_mode: string().optional(),
  }),
})
const PaymentRequestBodyAllOptionalAPI = PaymentRequestBodyAPI.extend({
  config: object({
    redirect_url: string().optional(),
  }).optional(),
  internal: object({
    effective_ux_mode: string().optional(),
  }).optional(),
  currency: string().optional(),
  payment_amount: number().optional(),
})

export const PaymentRequestResponseAPI = object({
  expires_at: string(),
  payment_request: PaymentRequestDataAPI.extend({
    config: object({
      redirect_url: string().optional(),
    }).optional(),
  })
    .partial()
    .nullable(),
  payment_request_id: string(),
  previous_state: PaymentRequestStateZ,
  state: PaymentRequestStateZ,
  state_context: StateContextSchemaAPI,
}).partial()

export const PaymentApiBridgeSchema = {
  paymentApiSetup: {
    data: SdkConfigSchema.extend({
      environment: string().optional(),
      sessionId: string().optional(),
      version: string().optional(),
    }),
    response: boolean(),
  },
  paymentApiSendAuthorizationRequest: {
    data: object({
      body: PaymentRequestBodyAPI,
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiPatchAuthorizationRequest: {
    data: object({
      id: string(),
      body: PaymentRequestBodyAllOptionalAPI,
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiGetAuthorizationRequest: {
    data: object({
      id: string(),
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiCancelConfirmation: {
    data: object({
      paymentConfirmationToken: string(),
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
}

export const CanMakePaymentOptionsSchema = object({
  currency: string(),
  country: string(),
  paymentAmount: number().optional(),
})
