import { detectDeviceBest, InteractionModes, triggerRedirect } from '@klarna/flow-interaction-mode'
import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'
import { API_BASE_URLS } from '@klarna-web-sdk/utils'
import { getIntegratorApi } from '@klarna-web-sdk/utils/src/klarnaIntegratorApi'

import { EffectiveUxModes, ErrorTypes, TrackingEvents } from '../constants'
import { PaymentRequestData, PaymentRequestDataAllOptional, PaymentRequestOptions } from '../schema'
import {
  PaymentRequestData as PaymentRequestDataType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from '../types'
import { emitUpdate } from '../utils/emitUpdate'
import { makePaymentRequest } from '../utils/makePaymentRequest'
import { initPerformActionsOnFocus, onCloseInteractionMode } from '../utils/onCloseInteractionMode'
import { openOnPageInteraction } from '../utils/openOnPageInteraction'
import { tracker } from '../utils/paymentTracker'
import { initPollingForInProgressState } from '../utils/pollForInProgressState'
import { store, storeUpdatePaymentRequest, storeUpdatePaymentRequestOptions } from '../utils/store'
import { updatePaymentRequest } from '../utils/updatePaymentRequest'

export async function initiate(
  paymentRequest?: Partial<PaymentRequestDataType>,
  options?: PaymentRequestOptionsType
) {
  const paymentRequestId = store.get('paymentRequestId')

  tracker().event(TrackingEvents.INITIATE_CALLED, {
    ...options,
    paymentRequestId,
  })

  if (paymentRequest) storeUpdatePaymentRequest(paymentRequest)
  if (options) storeUpdatePaymentRequestOptions(options)

  const paymentRequestSate = store.get('paymentRequestState')
  const _paymentRequest = store.get('paymentRequest') as PaymentRequestDataType
  const _options = store.get('paymentRequestOptions')
  const sdkConfig = store.get('config')
  const flowEndUrl = `${API_BASE_URLS[sdkConfig?.environment || 'playground']}/web-sdk/v1/flow-end/index.html`
  const integratorApi = getIntegratorApi(window)

  try {
    ;(paymentRequestId ? PaymentRequestDataAllOptional : PaymentRequestData).parse(_paymentRequest)
  } catch (error) {
    throw new PaymentError(ErrorTypes.INPUT_ERROR, 'Invalid PaymentRequest', error)
  }

  try {
    PaymentRequestOptions.parse(_options)
  } catch (error) {
    throw new PaymentError(ErrorTypes.INPUT_ERROR, 'Invalid PaymentRequestOptions', error)
  }

  const makeOrUpdatePaymentRequest = (effectiveUxMode: EffectiveUxModes) => {
    if (
      paymentRequestSate === 'PENDING_CONFIRMATION' ||
      paymentRequestSate === 'AUTHORIZED' ||
      !paymentRequestId
    ) {
      return makePaymentRequest(_paymentRequest, effectiveUxMode)
    } else {
      return updatePaymentRequest(paymentRequestId, _paymentRequest, effectiveUxMode)
    }
  }

  // interaction handler is provided by integrator
  if (integratorApi?.handleInteraction) {
    const effectiveUxMode =
      _options?.interactionMode === InteractionModes.ON_PAGE
        ? EffectiveUxModes.WINDOW
        : EffectiveUxModes.REDIRECT

    tracker().event(TrackingEvents.INITIATE_INTEGRATOR_HANDLED_INTERACTION_TRIGGERED, {
      effectiveUxMode,
      paymentRequestId,
    })

    try {
      const response = await makeOrUpdatePaymentRequest(effectiveUxMode)

      emitUpdate()
      initPollingForInProgressState()
      integratorApi.handleInteraction(response.stateContext?.distribution.url)

      if (integratorApi.onPaymentFlowClosed) {
        integratorApi.onPaymentFlowClosed(() => {
          tracker().event(TrackingEvents.INITIATE_INTEGRATOR_CLOSED_INTERACTION, {
            paymentRequestId: response.paymentRequestId,
          })
          onCloseInteractionMode()
        })
      }

      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: response.paymentRequestId,
      })
    } catch (error) {
      window.klarnaIntegratorApi.handleInteraction(`${flowEndUrl}?error_code=internalError`)
      throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, 'Initiate failed', error)
    }
  }

  const finalInteractionMode =
    !_options?.interactionMode || _options?.interactionMode === InteractionModes.DEVICE_BEST
      ? detectDeviceBest()
      : _options.interactionMode

  tracker().event(TrackingEvents.INITIATE_INTERACTION_MODE_TRIGGERED, {
    interactionMode: finalInteractionMode,
    paymentRequestId,
  })

  if (finalInteractionMode === InteractionModes.ON_PAGE) {
    try {
      const [interactionPromise, apiPromise] = await Promise.allSettled([
        openOnPageInteraction({ onCloseIframe: onCloseInteractionMode }),
        makeOrUpdatePaymentRequest(EffectiveUxModes.WINDOW),
      ])

      if (interactionPromise.status === 'rejected') {
        throw new PaymentError(
          ErrorTypes.TECHNICAL_ERROR,
          'Failed to open an interaction',
          interactionPromise.reason
        )
      }

      const { updateUrl, effectiveMode } = interactionPromise.value

      if (apiPromise.status === 'rejected') {
        updateUrl(`${flowEndUrl}?error_code=${ErrorTypes.TECHNICAL_ERROR}`)
        throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, 'API request failed', apiPromise.reason)
      }

      if (effectiveMode === EffectiveUxModes.WINDOW) {
        initPerformActionsOnFocus()
      } else {
        // unable to open a popup, needs to be reflected in backend as well
        await updatePaymentRequest(apiPromise.value.paymentRequestId, null, effectiveMode)
      }

      updateUrl(apiPromise.value.stateContext?.distribution.url)
      initPollingForInProgressState()
      emitUpdate()
      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: apiPromise.value.paymentRequestId,
      })
    } catch (error) {
      throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, 'Initiate failed', error)
    }
  }

  if (finalInteractionMode === InteractionModes.REDIRECT) {
    try {
      const response = await makeOrUpdatePaymentRequest(EffectiveUxModes.REDIRECT)

      emitUpdate()
      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: response.paymentRequestId,
      })
      triggerRedirect(response.stateContext?.distribution.url)
    } catch (error) {
      throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, 'Initiate failed', error)
    }
  }
}
