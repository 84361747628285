import { InteractionModes } from '@klarna/flow-interaction-mode'
import { Message, Messenger } from '@klarna/messenger'

import { ERROR_TITLES, errorHandler } from '../errors'
import { TokenExchangeError } from '../errors/token-exchange-error'
import { IdentityEvents } from '../identityEvents'
import { getSigninResponse } from '../utils/getSigninResponse'
import { loadingScreen } from '../utils/loadingScreen'
import { AuthorizationServer } from './AuthorizationServer'
import { IdentitySDKConfig } from './IdentitySDK'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

const RedirectEventName = 'redirect'

export class PopupManager {
  private authServer: AuthorizationServer
  private popup: Window
  private sdkConfig: IdentitySDKConfig
  private messenger: Messenger
  private identityEvents: IdentityEvents

  constructor({
    popup,
    authServer,
    sdkConfig,
  }: {
    popup: Window
    authServer: AuthorizationServer
    sdkConfig: IdentitySDKConfig
  }) {
    this.popup = popup
    this.authServer = authServer
    this.sdkConfig = sdkConfig
    this.identityEvents = IdentityEvents.getInstance()
  }

  public goto(authUrl: URL) {
    this.popup.document.body.innerHTML = loadingScreen

    this.messenger = new Messenger({
      target: { window: this.popup },
      origin: this.authServer.getIdpOrigin(),
      sourceID: 'SIWK-to-OIDC',
    })

    this.messenger.addMessageHandler(RedirectEventName, this.redirectMessageHandler)

    this.popup.location.href = authUrl.toString()
  }

  public redirectToErrorScreen = () => {
    if (this.popup) {
      const errorScreen = `${this.authServer.getIdpOrigin()}/error`
      this.popup.location.href = errorScreen
    }
  }

  private redirectMessageHandler = async (
    message: Message<{
      url: string
    }>
  ) => {
    if (!message.data) {
      throw new Error('Redirect event does not have data')
    }

    try {
      const url = new URL(message.data.url)
      const tokens = await this.authServer.verifyLogin({
        clientId: this.sdkConfig.clientId,
        urlWithLoginParams: url,
        isOnPageFlow: true,
      })

      this.identityEvents.emit('signin', getSigninResponse(tokens))

      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginSuccess,
        options: { flow: InteractionModes.ON_PAGE },
      })

      this.popup.close()
    } catch (error) {
      errorHandler(error, { errorTitle: ERROR_TITLES.VerifyLoginFailedForPopupFlow })

      if (error instanceof TokenExchangeError) {
        this.redirectToErrorScreen()
      } else {
        this.popup.close()
      }
    }
  }
}
